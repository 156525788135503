import React, { useState, useEffect } from 'react';
import './style.css';

const NoteItem = ({ note, index, updateNote, deleteNote }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(note);

  useEffect(() => {
    if (isEditing) {
      const timer = setTimeout(() => {
        updateNote(index, editedNote);
      }, 1000); // 1 second delay before saving
      return () => clearTimeout(timer);
    }
  }, [editedNote, isEditing, index, updateNote]);

  const handleNoteClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedNote(prevNote => ({
      ...prevNote,
      [name]: value,
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      setIsEditing(false);
    }
  };

  return (
    <div className={`note-item ${isEditing ? 'note-item--expanded' : ''}`} onClick={handleNoteClick}>
      {isEditing ? (
        <div className="note-item__edit">
          <input
            type="text"
            name="title"
            value={editedNote.title}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            className="note-item__title-input"
          />
          <textarea
            name="content"
            value={editedNote.content}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            className="note-item__content-input"
          />
        </div>
      ) : (
        <>
          <h3>{note.title}</h3>
          <p>{note.content}</p>
          <button onClick={(e) => { e.stopPropagation(); deleteNote(); }} className="note-item__delete-button">Delete</button>
        </>
      )}
    </div>
  );
};

export default NoteItem;
