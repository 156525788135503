import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import Loader from '../Loader';

function ErrorPage() {
    return (
        <div className="ErrorPage" role="alert">
            <h1>Error 404 (Bah alors on galère 😂)</h1>
            <p>Oops! The page you are looking for might be under construction or does not exist.</p>
            <Link to="/ene" role="button">Go to Home Page</Link>
            <Loader />
        </div>
    );
}

export default ErrorPage;