import React, { useEffect } from "react";
import './style.css';

const Hack = () => {
    useEffect(() => {
        function hacking() {
            var c = document.getElementById("locoalien");
            c.height = window.innerHeight;
            c.width = window.innerWidth;

            var letraTam = 12;
            var columnas = c.width / letraTam;

            var letras = [];
            for (var i = 0; i < columnas; i++) {
                letras[i] = 1;
            }
            var contexto = c.getContext('2d');

            function elegirTexto() {
                var caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                return caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }

            function dibujar() {
                contexto.fillStyle = "rgba(0,0,0,0.05)";
                contexto.fillRect(0, 0, c.width, c.height);

                contexto.fillStyle = "#0f0";
                contexto.font = letraTam + "px arial";

                for (var i = 0; i < letras.length; i++) {
                    var text = elegirTexto();
                    contexto.fillText(text, i * letraTam, letras[i] * letraTam);

                    if (letras[i] * letraTam > c.height && Math.random() > 0.975) {
                        letras[i] = 0;
                    }
                    letras[i]++;
                }
            }

            setInterval(dibujar, 120);
        }

        hacking();
    }, []);

    return (
        <div className="hack">
            <canvas id="locoalien"></canvas>
        </div>
    );
}

export default Hack;