import React from 'react';
import './style.css';

function Welcome() {
    return (
        <div className="welcome" role="region" aria-label="Welcome Section">
            <h1>Welcome</h1>
            <p>My name is Louis BERTRAND, I'm 18 and I'm in my first year of preparatory studies at the EPITA computer school in Paris. I'm passionate about computers.</p>
            <p>I've been writing computer-related courses for Instant Coding&Network since January 22, 2023. In addition, I share computer-related posts on Instagram.</p>
        </div>
    );
}

export default Welcome;