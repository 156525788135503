import React from 'react';

const ContactSection = () => {
    return (
        <section id="contact" className="py-5 bg-light">
            <div className="container">
                <h2 className="mb-0">Un projet de création web ?</h2>
                <h3 className="fw-light fs-5">Allons en discuter autour d'un café</h3>

                <div className="row gy-4 mt-4">
                    <div className="col-12 col-md-5">
                        {/* <img src="../../../img/grab-a-coffee.jpg" alt="Image d'une tasse de café" width="100%" className="rounded-3 shadow" /> */}
                    </div>
                    <div className="col-12 offset-md-1 col-md-6">
                        {/* Formulaire */}
                        <form className="row needs-validation" id="form">
                            <div className="col-6 my-2">
                                <label htmlFor="validationCustom01" className="form-label" required>Prénom</label>
                                <input type="text" className="form-control" id="prenom" />
                            </div>
                            <div className="col-6 my-2">
                                <label htmlFor="validationCustom02" className="form-label" required>Nom</label>
                                <input type="text" className="form-control" id="nom" />
                            </div>
                            <div className="col-12 my-2">
                                <label htmlFor="validationCustomUsername" className="form-label" required>Email address</label>
                                <div className="input-group has-validation">
                                    <input type="email" className="form-control" id="email" aria-describedby="inputGroupPrepend" />
                                </div>
                            </div>
                            <div className="col-12 my-2">
                                <label htmlFor="validationCustomUsername" className="form-label" required>Message</label>
                                <textarea name="message" className="form-control" id="message" rows="3"></textarea>
                            </div>
                            <div className="col-12 my-2">
                                <button type="button" onClick={() => /*sauvegarderDonnees()*/ console.log('envoyé')} className="btn btn-outline-warning" id="liveAlertBtn">Envoyer</button>
                            </div>
                            <div id="liveAlertPlaceholder"></div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;