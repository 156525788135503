import React, { useState, useEffect } from 'react';
import NoteItem from '../NoteItem';
import './style.css';

const NoteList = () => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const savedNotes = JSON.parse(localStorage.getItem('notes'));
    if (savedNotes) {
      setNotes(savedNotes);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('notes', JSON.stringify(notes));
  }, [notes]);

  const addNote = () => {
    const newNote = { title: '', content: '' };
    setNotes([...notes, newNote]);
  };

  const updateNote = (index, updatedNote) => {
    const newNotes = [...notes];
    newNotes[index] = updatedNote;
    setNotes(newNotes);
  };

  const deleteNote = (index) => {
    const newNotes = notes.filter((note, i) => i !== index);
    setNotes(newNotes);
  };

  return (
    <div className="note-list">
      <button onClick={addNote} className="note-list__add-button">Add Note</button>
      {notes.map((note, index) => (
        <NoteItem
          key={index}
          note={note}
          index={index}
          updateNote={updateNote}
          deleteNote={() => deleteNote(index)}
        />
      ))}
    </div>
  );
};

export default NoteList;
