import React, { useState, useEffect } from 'react';
import './style.css';

const Quiz = () => {
    document.title = 'Quizz Max Verstappen';
    const [username, setUsername] = useState('');
    const [userComment, setUserComment] = useState('');
    const [quizStarted, setQuizStarted] = useState(false);
    const [quizResult, setQuizResult] = useState('');
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);

    const questions = [
        {
            questionText: "What is Max Verstappen's nationality ?",
            answerOptions: [
                { answerText: "Dutch", isCorrect: true },
                { answerText: "German", isCorrect: false },
                { answerText: "Frrrrench", isCorrect: false },
                { answerText: "Austrian", isCorrect: false },
            ],
        },
        {
            questionText: "In which team did Max Verstappen start his Formula 1 career ?",
            answerOptions: [
                { answerText: "Red Bull", isCorrect: false },
                { answerText: "Ferrari", isCorrect: false },
                { answerText: "Mercedes", isCorrect: false },
                { answerText: "Toro Rosso", isCorrect: true },
            ],
        },
        {
            questionText: "What year did Max Verstappen make his Formula 1 debut ?",
            answerOptions: [
                { answerText: "2015", isCorrect: true },
                { answerText: "2016", isCorrect: false },
                { answerText: "2017", isCorrect: false },
                { answerText: "2018", isCorrect: false },
            ],
        },
        {
            questionText: "How many Formula 1 Grand Prix wins does Max Verstappen have ?",
            answerOptions: [
                { answerText: "47", isCorrect: false },
                { answerText: "37", isCorrect: false },
                { answerText: "57", isCorrect: true },
                { answerText: "67", isCorrect: false },
            ],
        },
        {
            questionText: "What was the first number Max Verstappen used in Formula 1 ?",
            answerOptions: [
                { answerText: "12", isCorrect: false },
                { answerText: "11", isCorrect: false },
                { answerText: "33", isCorrect: true },
                { answerText: "1", isCorrect: false },
            ],
        },
        {
            questionText: "What is his number right now ?",
            answerOptions: [
                { answerText: "12", isCorrect: false },
                { answerText: "11", isCorrect: false },
                { answerText: "33", isCorrect: false},
                { answerText: "1", isCorrect: true },
            ],
        },
        {
            questionText: "How many cans of Redbull there were in the collection ?",
            answerOptions: [
                { answerText: "12", isCorrect: true },
                { answerText: "11", isCorrect: false },
                { answerText: "33", isCorrect: false },
                { answerText: "1", isCorrect: false },
            ],
        },
        {
            questionText: "How many races did he win last year out of 22 ?",
            answerOptions: [
                { answerText: "10", isCorrect: false },
                { answerText: "19", isCorrect: true },
                { answerText: "15", isCorrect: false },
                { answerText: "22", isCorrect: false },
            ],
        },
        {
            questionText: "After this presentation, are you going to support him ?",
            answerOptions: [
                { answerText: "Yes of course", isCorrect: true },
                { answerText: "No K*rwa", isCorrect: false },
            ],
        },
    ];

    const handleStartQuiz = () => {
        setQuizStarted(true);
    };

    useEffect(() => {
        if (showScore) {
            let resultMessage = '';
            if (score === questions.length) {
                resultMessage = `Congratulations, ${username}! You answered all ${questions.length} questions correctly!`;
            } 
            if (score === 0) {
                resultMessage = `Sorry, ${username}! You didn't answer any questions correctly.`;
            } else {
                resultMessage = `${username}, you scored ${score} out of ${questions.length} questions.`;
            }
            setQuizResult(resultMessage);
        }
    }, [showScore, score, username, questions.length]);

    const handleAnswerButtonClick = (isCorrect) => {
        if (isCorrect) {
            setScore(prevScore => prevScore + 1);
            console.log("Correct answer!");
        } else {
            console.log("Wrong answer!");
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };

    const handleShareResult = () => {
        if (navigator.share) {
            let resultWithComment = `${quizResult}\n\nUser Comment: ${userComment}`;
            navigator.share({
                title: '🔴🔵 Max Verstappen Quiz Result 🔴🔵',
                text: "🔴🔵 *Result of the Max Verstappen Quizz* 🔴🔵\n \n"+resultWithComment,
            })
                .then(() => console.log('Shared successfully'))
                .catch((error) => console.error('Error sharing:', error));
        }
    };

    const handleCommentChange = (e) => {
        setUserComment(e.target.value);
    };

    return (
        <div className='app'>
            <div className='quiz'>
                {!quizStarted ? (
                    <div className='start-section'>
                        <h2>Welcome to the Max Verstappen Quiz</h2>
                        <input
                            type="text"
                            placeholder="Enter your username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <button onClick={handleStartQuiz}>Start Quiz</button>
                    </div>
                ) : (
                    <>
                        {!showScore && (
                            <>
                                <div className='question-section'>
                                    <div className='question-count'>
                                        <span>Question {currentQuestion + 1}</span>/{questions.length}
                                    </div>
                                    <div className='question-text'>{questions[currentQuestion].questionText}</div>
                                </div>
                                <div className='answer-section'>
                                    {questions[currentQuestion].answerOptions.map((answerOption, index) => (
                                        <button key={index} onClick={() => handleAnswerButtonClick(answerOption.isCorrect)}>{answerOption.answerText}</button>
                                    ))}
                                </div>
                            </>
                        )}

                        {showScore && (
                            <div className='score-section'>
                                <h2>Quiz Result</h2>
                                <p>{quizResult}</p>
                            </div>
                        )}

                        {showScore && (
                            <div className='comment-section'>
                                <textarea
                                    placeholder="Enter your comment..."
                                    value={userComment}
                                    onChange={handleCommentChange}
                                ></textarea>
                                <button onClick={handleShareResult}>Share</button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Quiz;