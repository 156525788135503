import React from 'react';

const Portfolio = () => {
    return (
        <section id="portfolio" className="py-5">
            <div className="container">
                <h2 className="mb-0">Mon portfolio</h2>
                <h3 className="fw-light fs-5">Projets perso et pro</h3>

                <div className="row gy-4 gy-md-0 mt-4">
                    {/* Premier Projet */}
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="card">
                            <img src="https://picsum.photos/300/150?random=1" className="card-img-top" alt="Projet 1" />
                            <div className="card-body">
                                <h5 className="card-title">Projet 1</h5>
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sollicitudin.
                                </p>
                                <a href="liu" className="btn btn-warning" data-bs-toggle="offcanvas" data-bs-target="#infoProjet1"
                                    aria-controls="infoProjet1">En savoir plus</a>
                            </div>
                            <div className="offcanvas offcanvas-bottom h-100" tabIndex="-1" id="infoProjet1"
                                aria-labelledby="titelProjet1">
                                {/* Contenu de l'offcanvas pour le Projet 1 */}
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="titelProjet1">Projet 1</h5>
                                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    {/* Carousel pour le Projet 1 */}
                                    <div id="carouselProject1" className="carousel slide shadow h-100" data-bs-ride="carousel"
                                        data-bs-touch="true">
                                        <div className="carousel-inner h-100">
                                            <div className="carousel-item h-100 active" data-bs-interval="10000">
                                                <img src="https://picsum.photos/1920/1080?random=1" className="d-block w-100"
                                                    alt="Image slide 1" />
                                                <div className="carousel-caption d-none d-md-block">
                                                    <h5>Slide 1</h5>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                                                        sollicitudin.
                                                    </p>
                                                </div>
                                            </div>
                                            {/* Ajoutez ici d'autres éléments de carrousel pour le Projet 1 */}
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselProject1"
                                            data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselProject1"
                                            data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Deuxième Projet */}
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="card">
                            <img src="https://picsum.photos/300/150?random=2" className="card-img-top" alt="Projet 2" />
                            <div className="card-body">
                                <h5 className="card-title">Projet 2</h5>
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sollicitudin.
                                </p>
                                <a className="btn btn-warning" data-bs-toggle="offcanvas" data-bs-target="#infoProjet2"
                                    aria-controls="infoProjet2">En savoir plus</a>
                            </div>
                            <div className="offcanvas offcanvas-bottom h-100" tabIndex="-1" id="infoProjet2"
                                aria-labelledby="titelProjet2">
                                {/* Contenu de l'offcanvas pour le Projet 2 */}
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="titelProjet2">Projet 2</h5>
                                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    {/* Carousel pour le Projet 2 */}
                                    <div id="carouselProject2" className="carousel slide shadow h-100" data-bs-ride="carousel"
                                        data-bs-touch="true">
                                        <div className="carousel-inner h-100">
                                            <div className="carousel-item h-100 active" data-bs-interval="10000">
                                                <img src="https://picsum.photos/1920/1080?random=4" className="d-block w-100"
                                                    alt="Image slide 1" />
                                                <div className="carousel-caption d-none d-md-block">
                                                    <h5>Slide 1</h5>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                                                        sollicitudin.
                                                    </p>
                                                </div>
                                            </div>
                                            {/* Ajoutez ici d'autres éléments de carrousel pour le Projet 2 */}
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselProject2"
                                            data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselProject2"
                                            data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Ajoutez d'autres projets similaires ici */}
                </div>
            </div>
        </section>
    );
}

export default Portfolio;