import React from 'react';
import '../../EnComponents/Welcome/style.css';

function Welcome() {
    return (
        <div className="welcome" role="region" aria-label="Welcome Section">
            <h1>Bienvenu</h1>
            <p>Je m'appelle Louis BERTRAND, j'ai 18 ans et je suis en première année de prépa à l'école d'informatique EPITA à Paris. Je suis passionné par l'informatique.</p>
            <p>J'écris des cours liés à l'informatique pour Instant Coding&Network depuis le 22 janvier 2023. De plus, je partage des publications sur Instagram sur le thème de l'informatique.</p>
        </div>
    );
}

export default Welcome;