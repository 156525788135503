import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import './style.css';

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTime = time.toLocaleTimeString();

  return (
    <div className="clock-container">
      <h1 className="clock-title">Heure actuelle :</h1>
      <h2 className="clock-time">{formattedTime}</h2>
    </div>
  );
};

const Chronometer = () => {
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [laps, setLaps] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setTime(prevTime => prevTime + 10);
      }, 10);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  const handleStartStop = () => {
    setIsActive(!isActive);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    setLaps([]);
  };

  const handleLap = () => {
    const lastLapTime = laps.length > 0 ? laps[laps.length - 1].end : 0;
    setLaps([...laps, { start: lastLapTime, end: time }]);
  };

  const formatTime = (time) => {
    const milliseconds = ("0" + (Math.floor(time / 10) % 100)).slice(-2);
    const seconds = ("0" + (Math.floor(time / 1000) % 60)).slice(-2);
    const minutes = ("0" + (Math.floor(time / 60000) % 60)).slice(-2);
    return `${minutes}:${seconds}:${milliseconds}`;
  };

  const getLapDuration = (lap) => {
    return formatTime(lap.end - lap.start);
  };

  return (
    <div className="chronometer">
      <h1>{formatTime(time)}</h1>
      <div>
        <button onClick={handleStartStop}>
          {isActive ? 'Stop' : 'Start'}
        </button>
        <button onClick={handleReset}>Reset</button>
        {isActive && <button onClick={handleLap}>Lap</button>}
      </div>
      <ul>
        {laps.slice().reverse().map((lap, index) => (
          <li key={index}>Lap {laps.length - index}: {getLapDuration(lap)}</li>
        ))}
      </ul>
    </div>
  );
};

const QuoteInput = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const savedQuote = Cookies.get('quote');
    if (savedQuote) {
      setQuote(savedQuote);
    }
  }, []);

  const handleInputChange = (event) => {
    const newQuote = event.target.value;
    setQuote(newQuote);
    Cookies.set('quote', newQuote, { expires: 7 }); // Expire dans 7 jours
  };

  return (
    <div className="quote-input-container">
      <h1 className="quote-title">Entrez votre Note :</h1>
      <textarea
        className="quote-textarea"
        value={quote}
        onChange={handleInputChange}
        placeholder="Écrivez votre note ici..."
      />
    </div>
  );
};

const Useless = () => {
  return (
    <div>
      <Clock />
      <Chronometer />
      <QuoteInput />
    </div>
  );
};

export default Useless;
