import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import NoteInput from '../NoteInput';
import NoteList from '../NoteList';
import './style.css';

const MainNote = () => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const savedNotes = Cookies.get('notes');
    if (savedNotes) {
      setNotes(JSON.parse(savedNotes));
    }
  }, []);

  useEffect(() => {
    Cookies.set('notes', JSON.stringify(notes), { expires: 7 });
  }, [notes]);

  const addNote = (note) => {
    setNotes([...notes, note]);
  };

  const deleteNote = (index) => {
    const newNotes = notes.filter((_, i) => i !== index);
    setNotes(newNotes);
  };

  return (
    <div className="appNote">
      <h1>Note Keeper</h1>
      <NoteInput addNote={addNote} />
      <NoteList notes={notes} deleteNote={deleteNote} />
    </div>
  );
};

export default MainNote;