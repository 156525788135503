import React, { useState } from 'react';
import './style.css';

const NoteInput = ({ addNote }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleAddNote = () => {
    if (title.trim() && content.trim()) {
      addNote({ title, content });
      setTitle('');
      setContent('');
    }
  };

  return (
    <div className="note-input">
      <input type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} />
      <textarea placeholder="Contenu" value={content} onChange={(e) => setContent(e.target.value)} />
      <button onClick={handleAddNote}>Ajouter</button>
    </div>
  );
};

export default NoteInput;